import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client'
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItem,
  MenuLink,
} from "@reach/menu-button";
import { ChatAltIcon, FolderIcon } from '@heroicons/react/outline'
import { DotsVerticalIcon } from '@heroicons/react/solid'
import { BotModulesOverviewRoute_BotModuleTable_botModules } from '../__generated__/BotModulesOverviewRoute_BotModuleTable_botModules';
import { BotModulesOverviewRoute_BotModuleTable_folders } from '../__generated__/BotModulesOverviewRoute_BotModuleTable_folders';
import { BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule } from '../__generated__/BotModulesOverviewRoute_BotModuleTableBotModuleRow_botModule';
import { BotModulesOverviewRoute_BotModuleTableFolderRow_folder } from '../__generated__/BotModulesOverviewRoute_BotModuleTableFolderRow_folder';
import { BotModulesOverviewRoute_DeleteBotModuleMutation, BotModulesOverviewRoute_DeleteBotModuleMutationVariables } from '../__generated__/BotModulesOverviewRoute_DeleteBotModuleMutation';
import { BotModulesOverviewRoute_DeleteFolderMutation, BotModulesOverviewRoute_DeleteFolderMutationVariables } from '../__generated__/BotModulesOverviewRoute_DeleteFolderMutation';
import { BotModulesOverviewRouteQuery } from '../__generated__/BotModulesOverviewRouteQuery';
import { SearchBox } from '../components/Searchbox';
import { Button } from '../components/Button'
import useHasChanged from '../hooks/has-changed'
import { VFCwF } from '../types'
import {useTranslation} from "react-i18next";
import LocalTime from "../components/LocalTime";

interface OrdersOverviewRouteProps {
  companyId: string
  userRole: string
}

const OrdersOverviewRoute: React.VFC<OrdersOverviewRouteProps> = ({ companyId, userRole }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);

  const { data, loading, error, refetch } = useQuery<OrdersOverviewRouteQuery>(gql`
    query OrdersOverviewRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        orders {
          createdAt
          amount
          price
          department {
            name
          }   
        }
      }
    }
  `, {
    variables: {
      companyId
    }
  });

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch bot modules when the modal is closed by a navigate action.
      refetch()
    }
  });

  console.log(data)

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <h1 className="text-2xl font-semibold text-gray-900 truncate">
              {t('orders')}
            </h1>
          </div>

          <div className="flex items-center space-x-3">
          </div>
        </div>
      </header>

      <main className="mt-8">
        {loading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p>{t('error')}</p>
        ) : (
          <div className="overflow-auto border border-gray-200 rounded-lg relative">
            <table className="min-w-full">
              <thead className="bg-gray-50">
              <tr className="border-b border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
                <th scope="col" className="pl-8 py-3" >{t('name')}</th>
                <th scope="col" className="px-8 py-3" >{t('created_at')}</th>
                <th scope="col" className="px-8 py-3" >{t('amount')}</th>
                <th scope="col" className="px-8 py-3" >{t('price')}</th>
              </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
              { data?.company?.orders?.map((order) => (
                <tr>
                  <td scope="row" className="px-8 py-3 text-xs" >
                    {order.department.name}
                  </td>
                  <td scope="row" className="px-8 py-3 text-xs" >
                    <LocalTime dateTime={order.createdAt} format="{YYYY}-{MM}-{DD} {HH}:{mm}:{ss}" />
                  </td>
                  <td scope="row" className="px-8 py-3 text-xs" >
                    {order.amount}
                  </td>
                  <td scope="row" className="px-8 py-3 text-xs" >
                    {order.price}
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
        )}
      </main>
    </div>
  );
}

export default OrdersOverviewRoute;
