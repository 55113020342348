import React from 'react';
import { DefaultValues, SubmitHandler, useForm, useFormState } from 'react-hook-form'
import { gql } from '@apollo/client';
import { DepartmentForm_department } from '../__generated__/DepartmentForm_department';
import { Button, Appearance } from './Button';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { TextareaField } from './textarea-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { SelectField } from './select-field';
import * as yup from 'yup';
import {
  WhatsappBroadcastForm_whatsappBroadcastTemplates
} from "../__generated__/WhatsappBroadcastForm_whatsappBroadcastTemplates";
import {DepartmentDesiredKind, DepartmentKind} from "../__generated__/globalTypes";
import { useTranslation } from "react-i18next";
import useDirectUploadMutation from "../hooks/direct-upload-mutation";
import { BooleanInput, BooleanInputField } from './boolean-input-field';

const DepartmentSchema = yup.object({
  name: yup.string().required().label("Name"),
  closureMessage: yup.string().required().label("Closure Message"),
  description: yup.string().label("Description"),
  price: yup.number().label("Price"),
  showQaModules: yup.boolean().label('Show Qa modules'),
  language: yup.string().oneOf(['en', 'nl', '']).nullable().label("Language"),
  kind: yup.string().oneOf(['default', 'scheduled', 'template', 'available']).nullable().label("Kind"),
  desiredKind: yup.string().oneOf(['default_desired', 'scheduled_desired']).nullable().label("Desired kind"),
  whatsappBroadcastTemplateId: yup.string().nullable().when('kind', {
    is: (kind: string) => kind == 'scheduled' || kind == 'available' || kind == 'template',
    then: yup.string().nullable().required().label("Template")
  }).label("Template"),
})

export interface DepartmentValues {
  name: string;
  closureMessage: string
  description: string
  whatsappBroadcastTemplateId: string | null
  kind: DepartmentKind
  desiredKind: DepartmentDesiredKind
  price: number
  showQaModules: boolean
  language: string;
  imageUrl: string | null
  image: {
    blobId: FileList | null | string
    _destroy: boolean
  }
}

export interface DepartmentFormProps {
  userRole: string | null
  department?: DepartmentForm_department
  whatsappBroadcastTemplates: WhatsappBroadcastForm_whatsappBroadcastTemplates[]
  onCancel: () => void;
  onSubmit: SubmitHandler<DepartmentValues>;
}

export const DepartmentForm: VFCwF<DepartmentFormProps> = ({ userRole, department, whatsappBroadcastTemplates, onCancel, onSubmit } ) => {
  const defaultValues: DefaultValues<DepartmentValues> = {
    name: department?.name,
    whatsappBroadcastTemplateId: department?.whatsappBroadcastTemplateId || null,
    closureMessage: department?.closureMessage,
    language: department?.language || 'nl',
    kind: department?.kind,
    desiredKind: department?.desiredKind || 'default_desired',
    price: department?.price || 0.0,
    showQaModules: department?.showQaModules,
    description: department?.description || '',
    image: {
      blobId: department?.imageUrl || null,
      _destroy: false
    },
  };

  const qrCode = department?.qrCode;

  const { register, handleSubmit, formState, setValue } = useForm<DepartmentValues>({defaultValues, resolver: yupResolver(DepartmentSchema)})
  const { t, i18n } = useTranslation();
  const directUpload = useDirectUploadMutation()

  let [selectedTemplate, setSelectedTemplate] = React.useState<WhatsappBroadcastForm_whatsappBroadcastTemplates>(whatsappBroadcastTemplates[0])
  let [selectedKind, setSelectedKind] = React.useState<string>(defaultValues?.kind || '')

  function handleKindChanged(event: any) {
    const element = event.target as HTMLSelectElement;
    const value = element.options[element.selectedIndex].value;
    setSelectedKind(value)
  }

  function showPreview(event: React.ChangeEvent){
    const element = event.currentTarget as HTMLSelectElement;
    const value = element.options[element.selectedIndex].value;
    const template = whatsappBroadcastTemplates.find(x => x.id == value);
    if (template != null) {
      setSelectedTemplate(template);
    }
  }

  const onFormSubmit: SubmitHandler<DepartmentValues> = async values => {
    let signedBlobId = (values?.image.blobId instanceof FileList)
        ? await directUpload(values.image.blobId![0])
        : null

    return onSubmit({ ...values, image: { _destroy: values.image._destroy, blobId: signedBlobId }} )
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onFormSubmit)}>
      <div className="space-y-6">
        {userRole == 'superadmin' ? (
          <SelectField label={t('type')} {...register('kind')} onChange={(event)=> handleKindChanged(event) } errors={formState.errors} >
            {['default', 'scheduled', 'template', 'available'].map(enumType => (
                <option key={enumType} value={enumType}>{t('department_kind_' + enumType)}</option>
            ))}
          </SelectField>
        ) : (
          <SelectField label={t('type')} {...register('kind')} onChange={(event)=> handleKindChanged(event) } errors={formState.errors} >
            {['default', 'scheduled', 'available'].map(enumType => (
              <option key={enumType} value={enumType}>{t('department_kind_' + enumType)}</option>
            ))}
          </SelectField>
        )}
        {selectedKind == 'scheduled' && <p>{t('scheduled_explanation')}</p>}
        {selectedKind == 'default' && <p>{t('default_explanation')}</p>}
        {selectedKind == 'template' &&
          <>
            <SelectField label={t('desiredKind')} {...register('desiredKind')} errors={formState.errors} >
              {['default_desired', 'scheduled_desired'].map(enumType => (
                <option key={enumType} value={enumType}>{t('department_desired_kind_' + enumType)}</option>
              ))}
            </SelectField>
            <p>{t('template_explanation')}</p>
          </>}
        <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />
        <SelectField label={t('language')} {...register('language')} errors={formState.errors} >
          <option key={'x'} className="text-gray-500" value="">{t('use_company_language')}</option>
          <option key={'en'} value="en">{t('english')}</option>
          <option key={'nl'} value="nl">{t('nederland')}</option>
        </SelectField>
        <TextareaField showHint={false} label={t('closure_message')} rows={5} {...register('closureMessage')} errors={formState.errors} />

        <div className="col-span-12">
          <label className="flex w-full py-4 justify-center items-baseline">
            <span className="flex-1 block text-sm font-medium text-gray-700">{t('show_qa')}
            </span>
            <div>
              <BooleanInput label={t('show_qa')} {...register('showQaModules')} errors={formState.errors} className={'mt-0'} />
            </div>
          </label>
        </div>
        <SelectField label={t('whatsapp_broadcast_template')} {...register('whatsappBroadcastTemplateId')} onChange={event => showPreview(event)}  errors={formState.errors}>
          {whatsappBroadcastTemplates.map(whatsappBroadcastTemplate => (
            <option key={whatsappBroadcastTemplate.id} value={whatsappBroadcastTemplate.id}>
              {whatsappBroadcastTemplate.displayName} ({whatsappBroadcastTemplate.language})
            </option>
          ))}
        </SelectField>
      </div>

      {selectedTemplate && (
        <div className="whatsapp-message">
          <div>
            <h2>{selectedTemplate.header}</h2>
            <div dangerouslySetInnerHTML={{__html: selectedTemplate.body || ''}}></div>
          </div>
        </div>
      )}

      <div className='pt-4'>
      <label className="mb-2 block text-sm font-medium text-gray-700">Account aanmaken</label>

        { department?.qrCode &&
          <div>
            <div dangerouslySetInnerHTML={{__html: department?.qrCode}}/>
            <a href={`/assets/${department.companyId}/departments/${department.id}/qr_code`} target="_blank" className="mt-4 flex items-center">
              Downloaden
              <span className="material-symbols-outlined ml-2">
                download
              </span>
            </a>
          </div>
        }
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

DepartmentForm.fragments = {
  department: gql`
    fragment DepartmentForm_department on Department {
      id
      companyId
      name
      closureMessage
      qrCode
      language
      kind
      desiredKind
      whatsappBroadcastTemplateId
      price
      showQaModules
      description
      imageUrl
    }
  `
}

export default DepartmentForm
