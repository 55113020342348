import * as React from 'react';
import { DefaultValues, SubmitHandler, useForm } from 'react-hook-form'
import { gql, useMutation } from '@apollo/client';
import { BotModuleCloneForm_botModule } from '../__generated__/BotModuleCloneForm_botModule';
import { BotModuleCloneForm_companies } from '../__generated__/BotModuleCloneForm_companies';
import { BotModuleCloneForm_CloneBotModuleMutation, BotModuleCloneForm_CloneBotModuleMutationVariables } from '../__generated__/BotModuleCloneForm_CloneBotModuleMutation';
import { Button, Appearance } from './Button';
import { SelectField } from './select-field';
import { TextInputField } from './text-input-field';
import { VFCwF } from '../types';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useTranslation} from "react-i18next";

const BotModuleCloneSchema = yup.object({
  name: yup.string().required().label("Name"),
  companyId: yup.string().label("Company")
})

export interface BotModuleValues {
  name: string;
  companyId: string
}

export interface BotModuleCloneFormProps {
  botModule: BotModuleCloneForm_botModule
  companies: BotModuleCloneForm_companies[]
  onCancel: () => void;
  onSubmitted?: () => void;
}

export const BotModuleCloneForm: VFCwF<BotModuleCloneFormProps> = ({
  botModule,
  companies,
  onCancel,
  onSubmitted
}) => {
  const { t, i18n } = useTranslation();

  const defaultValues: DefaultValues<BotModuleValues> = {
    name: `${t('copy_of')} ${botModule.name}`,
    companyId: botModule.companyId
  };
  const { register, handleSubmit, formState } = useForm<BotModuleValues>({ defaultValues, resolver: yupResolver(BotModuleCloneSchema) })

  const [cloneButModule] = useMutation<BotModuleCloneForm_CloneBotModuleMutation, BotModuleCloneForm_CloneBotModuleMutationVariables>(
    gql`
      mutation BotModuleCloneForm_CloneBotModuleMutation($input: CloneBotModuleMutationInput!) {
        cloneBotModule(input: $input) {
          botModule {
            id
          }
        }
      }
    `
  )

  const onSubmit: SubmitHandler<BotModuleValues> = async (values) => {
    await cloneButModule({
      variables: { input: { id: botModule.id, ...values } }
    })

    if (onSubmitted) {
      onSubmitted()
    }
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={handleSubmit(onSubmit)}>
      <div className="space-y-6">
        <TextInputField label={t('name')} {...register('name')} errors={formState.errors} />

        <SelectField label={t('company')} {...register('companyId')} errors={formState.errors} >
          {companies.map(company => (
            <option key={company.id} value={company.id}>
              {company.name}
            </option>
          ))}
        </SelectField>
      </div>

      <div className="pt-5">
        <div className="flex justify-end space-x-3">
          <Button appearance={Appearance.Outline} type="button" onClick={onCancel}>
            {t('cancel')}
          </Button>
          <Button type="submit" disabled={formState.isSubmitting}>
            {t('save')}
          </Button>
        </div>
      </div>
    </form>
  )
}

BotModuleCloneForm.fragments = {
  botModule: gql`
    fragment BotModuleCloneForm_botModule on BotModule {
      id
      name
      companyId
    }
  `,
  companies: gql`
    fragment BotModuleCloneForm_companies on Company {
      id
      name
    }
  `,
}
