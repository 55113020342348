import * as React from 'react';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { XIcon } from '@heroicons/react/outline'

export interface SlideOverProps {
  initialFocusRef?: React.RefObject<any>
  title: string;
  onClose: () => void;
}

export const SlideOver: React.FC<SlideOverProps> = ({ children, initialFocusRef, title, onClose }) => {
  return (
    <DialogOverlay
      className="bg-gray-500 bg-opacity-75"
      initialFocusRef={initialFocusRef}
      isOpen={true}
      onDismiss={onClose}
    >
      <DialogContent aria-label="dialogOverlay" className="bg-transparent fixed inset-y-0 right-0 m-0 p-0 pl-10 w-auto max-w-full flex">
        <div className="w-screen max-w-md">
          <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
            <div className="px-6">
              <div className="flex items-start justify-between">
                <h1 className="text-lg font-medium text-gray-900">
                  {title}
                </h1>

                <div className="ml-3 h-7 flex items-center">
                  <button type="button" className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" onClick={onClose}>
                    <span className="sr-only">Close panel</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
            <div className="mt-6 relative flex-1 px-6">
              {children}
            </div>
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  )
}
