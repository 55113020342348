import React, { useState } from 'react';
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom';
import { gql, useQuery, useMutation } from '@apollo/client';
import { DepartmentsDetailsRouteQuery } from '../__generated__/DepartmentsDetailsRouteQuery';
import { BotModuleList } from '../components/BotModuleList';
import { SearchBox } from '../components/Searchbox';
import { DepartmentDetailsRoute_DeleteDepartmentBotModule, DepartmentDetailsRoute_DeleteDepartmentBotModuleVariables } from '../__generated__/DepartmentDetailsRoute_DeleteDepartmentBotModule';
import { DepartmentDetailsRoute_UpdateDepartmentConnectBotModule, DepartmentDetailsRoute_UpdateDepartmentConnectBotModuleVariables } from '../__generated__/DepartmentDetailsRoute_UpdateDepartmentConnectBotModule';
import { DepartmentDetailsRoute_CreateDepartmentBotModule, DepartmentDetailsRoute_CreateDepartmentBotModuleVariables } from '../__generated__/DepartmentDetailsRoute_CreateDepartmentBotModule';
import useHasChanged from "../hooks/has-changed";
import {useTranslation} from "react-i18next";
import {Appearance, Button} from "../components/Button";

interface DepartmentsDetailsRouteProps {
  companyId: string
}

const DepartmentsDetailsRoute: React.VFC<DepartmentsDetailsRouteProps> = ({ companyId }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t, i18n } = useTranslation();

  const state = location.state as { backgroundLocation?: Location };
  const hasStateChanged = useHasChanged(state?.backgroundLocation);
  const [showIframe, setShowIframe] = React.useState<boolean>(false)
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const { departmentId } = useParams<'departmentId'>();
  const { data, loading, error, refetch } = useQuery<DepartmentsDetailsRouteQuery>(gql`
    query DepartmentsDetailsRouteQuery($departmentId: ID!) {
      department(id: $departmentId) {
        id
        name
        kind
        desiredKind
        templateDepartmentId
        templateEditable
        editable
        restricted
        publicVisible
        companyId
        company {
          botModules {
            id
            name
          }
        }

        ...DepartmentBotModules
      }
    }

    ${BotModuleList.fragments.departmentBotModules}
  `, { 
    variables: { 
      departmentId: departmentId as string 
    }, fetchPolicy: 'network-only' 
  }
  );

  React.useEffect(() => {
    if (hasStateChanged && !state?.backgroundLocation) {
      // Refetch departments when the modal is closed by a navigate action.
      refetch()
    }
  });

  const [createDepartmentBotModule] = useMutation<DepartmentDetailsRoute_CreateDepartmentBotModule, DepartmentDetailsRoute_CreateDepartmentBotModuleVariables>(gql`
    mutation DepartmentDetailsRoute_CreateDepartmentBotModule($input: CreateDepartmentBotModuleMutationInput!) {
      createDepartmentBotModule(input: $input) {
        departmentBotModule {
          id
          position
        }
        errors
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['DepartmentsDetailsRouteQuery']
  })

  const [deleteDepartmentBotModule] = useMutation<DepartmentDetailsRoute_DeleteDepartmentBotModule, DepartmentDetailsRoute_DeleteDepartmentBotModuleVariables>(gql`
    mutation DepartmentDetailsRoute_DeleteDepartmentBotModule($input: DeleteDepartmentBotModuleMutationInput!) {
      deleteDepartmentBotModule(input: $input) {
        deletedId
      }
    }
  `, {
    awaitRefetchQueries: true,
    refetchQueries: ['DepartmentsDetailsRouteQuery']
  })

  const [updateDepartmentConnectBotModule] = useMutation<DepartmentDetailsRoute_UpdateDepartmentConnectBotModule, DepartmentDetailsRoute_UpdateDepartmentConnectBotModuleVariables>(gql`
    mutation DepartmentDetailsRoute_UpdateDepartmentConnectBotModule($input: UpdateDepartmentConnectBotModuleMutationInput!) {
      updateDepartmentConnectBotModule(input: $input) {
        department {
          id
        }
      }
    }
  `
  )

  function handleDepartmentBotModulesSorted(departmentBotModules: { id: string }[]) {
    updateDepartmentConnectBotModule({
      variables: { input: { id: departmentId as string, departmentBotModuleIds: departmentBotModules.map(dbm => dbm!.id)} },
      update: (cache, { data: { updateDepartmentConnectBotModule: {department } } }) => {
        cache.modify({
          id: cache.identify(department),
          fields: {
            departmentBotModules() {
              return departmentBotModules;
            }
          }
        })
      }
    })
  }

  const toggleIframe = () => {
    setShowIframe(false);
    setTimeout(function(){
      setShowIframe(true)
    }, 100)
  }

  async function handleRemoveDepartmentBotModule(id: string) {
    await deleteDepartmentBotModule({ variables: { input: { id: id } } })
  }

  async function onSelectedBotModule(id: string) {
    await createDepartmentBotModule({
      variables: { input: { departmentId: departmentId as string, botModuleId: id } }
    }).then(({ data }) => data!.createDepartmentBotModule!.departmentBotModule )
  }

  const addableBotModules = React.useMemo(() => {
    const connectedBotModuleIds = data?.department?.departmentBotModules?.map(dbm => dbm?.botModule.id)
    return data?.department?.company?.botModules?.filter(bm => !connectedBotModuleIds?.includes(bm.id)) ?? []
  }, [data])

  return (
    <div>
      <header>
        <div className="flex items-center justify-between space-x-5 h-10">
          <div className="flex items-center text-2xl font-semibold text-gray-300">
            <Link to={`/${companyId}/departments`} className="hover:text-gray-400">
              {t('departments')}
            </Link>

            <svg className="flex-shrink-0 h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
            </svg>

            <h1 className="text-gray-900 truncate">
              {t('editing')} {data?.department?.name ?? '...'}
            </h1>
          </div>
        </div>
      </header>

      <main className="mt-8">
        <div id="department_detail_view"></div>
        { loading && <p>{t('loading')}</p> }
        { error &&  <p>{t('error')}</p>}
        { (!data || !data.department || data.department.companyId !== companyId) && <p>{t('not_found')}</p> }
        { data?.department && data!.department.templateEditable && (
            <>
              <h2 className="text-gray-900 font-semibold mr-4 inline-block">{t('bot_module_search')}</h2>
              <SearchBox className="w-80 mb-8 inline-block"
                options={addableBotModules}
                disabled={loading}
                onSelect={onSelectedBotModule}
                placeholder={t('type_name_or_click_to_add')}
              />
            </>
        )}
        <h2 className="text-gray-900 mb-4 font-semibold">{t('bot_modules_added')}:</h2>
        {(data?.department?.kind == 'available' || data?.department?.desiredKind == 'available_desired') && 
            <>
              {showAll ? (
                  <div>
                    <button style={{
                              border: '1px solid #d1d5dc',
                              padding: '8px'}}
                              onClick={() => setShowAll(false)}>{t('only_show_available')}</button>
                    <button style={{
                              border: '1px solid #d1d5dc',
                              padding: '8px',
                              background: '#dfe0e3'}} >{t('show_all')}</button>
                  </div>
                ) : (
                  <div>
                    <button style={{
                              border: '1px solid #d1d5dc',
                              padding: '8px',
                              background: '#dfe0e3'}}>{t('only_show_available')}</button>
                    <button style={{
                              border: '1px solid #d1d5dc',
                              padding: '8px'}} 
                            onClick={() => setShowAll(true)}>{t('show_all')}</button>
                  </div>
                )}
            </>
        }
        { data?.department && <div className="flex">
            <BotModuleList showAll={showAll} department={data.department} onSorted={handleDepartmentBotModulesSorted} onDelete={handleRemoveDepartmentBotModule} />
          {showIframe ? (
            <>
              <div className="web-preview">
                <div className="inner">
                  <iframe className="w-100" src={`/webchat/preview?department_id=${data?.department.id}&company_id=${companyId}`}></iframe>
                </div>
                <div className="web-preview-buttons">
                  <Button appearance={Appearance.Outline} type="button" onClick={(e) => setShowIframe(false)}>
                    {t('hide_live_preview')}
                  </Button>
                  <Button type="button" onClick={(e) => toggleIframe()}>
                    {t('refresh_live_preview')}
                  </Button>
                </div>
              </div>
            </> ) : (
            <>
              <div className="web-preview">
                <div className="inner">
                </div>
                <div className="web-preview-buttons">
                  <Button appearance={Appearance.Outline} type="button" onClick={(e) => setShowIframe(true)}>
                    {t('show_live_preview')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>}
      </main>
    </div>
  )
}

export default DepartmentsDetailsRoute;
