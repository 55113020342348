import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import {UserGroupIcon, BookOpenIcon, DotsVerticalIcon} from '@heroicons/react/solid';
import { ChartSquareBarIcon, OfficeBuildingIcon } from '@heroicons/react/outline';
import { DashboardRouteQuery_company_departments } from '../__generated__/DashboardRouteQuery';
import {ChartComponent} from "../components/Chart";
import {useTranslation} from "react-i18next";

interface StatsCardProps {
  title: string;
  value: string;
  children?: Object;
  href?: string;
}

interface StatsCardSubscriptionProps {
  userRole: string | null
  id: string
  companyId: string
  title: string;
  value: string;
  subValue: string;
  automaticRenewal: boolean;
  date: string;
  children?: Object;
  href?: string;
}


const StatsCard: React.VFC<StatsCardProps> = ({ title, value, children, href } ) => {
  return (
    <a className={`relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-start justify-between space-x-3 ${href && "hover:border-gray-400 hover:bg-gray-50"} focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500`} href={href} >
      <div className="flex flex-col text-gray-400">
        {title}
        <p className="text-lg font-medium text-gray-900">{value}</p>
      </div>
      {children}
    </a>
  )
}

const StatsCardSubscription: React.VFC<StatsCardSubscriptionProps> = ({ userRole, companyId, id, title, value, subValue, date, automaticRenewal, children, href } ) => {
  const location = useLocation();
  const { t, i18n } = useTranslation();

  return (
    <div className={`text-white relative rounded-lg px-6 py-5 shadow-sm flex items-start justify-between space-x-3 bg-cyan-500 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 color-white`} href={href} >
      <div>
        <div className="flex text-white">
          {title}
          <span className="text-white ml-2">
            <span>{t('used')}: </span>
            <span>{subValue}</span>
            <span> / </span>
            <span className="text-white text-xs">{value}</span>
          </span>
        </div>
        <div>
          <p className="text-xs mt-2 items-start flex">
            <span className="text-xs material-symbols-outlined pr-2">schedule</span>
            <span>{t('valid_until')}: {date}</span>
            {automaticRenewal && <span className={'pl-2'}>{t('is_automatically_renewed')}</span>}
          </p>
          {userRole == 'superadmin' &&
            <div className="mt-4">
              <Link to={`/${companyId}/subscriptions/${id}/edit`} state={{backgroundLocation: location}}>
                <span>{t('edit')}</span>
              </Link>
            </div>
          }
        </div>
      </div>
      {children}
    </div>
  )
}

interface DepartmentsStatsTableProps {
  companyId: string;
  departments: DashboardRouteQuery_company_departments[];
}

const DepartmentsStatsTable: React.VFC<DepartmentsStatsTableProps> = ({ companyId, departments }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="overflow-auto border border-gray-200 rounded-lg mt-8 relative">
      <div className="bg-white p-4 w-full">
        <h2 className="pl-4 text-lg font-semibold text-gray-900 truncate">
          {t('department_stats')}
        </h2>
      </div>
      <table className="min-w-full">
        <thead className="bg-gray-50 w-full">
          <tr className="border-y border-gray-200 text-left text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-nowrap">
            <th scope="col" className="pl-8 py-3" >
              {t('name')}
            </th>
            <th scope="col" className="px-8 py-3" >
              {t('no_of_bot_modules')}
            </th>
            <th scope="col" className="px-8 py-3" >
              {t('no_of_bot_users')}
            </th>
            <th scope="col" className="pr-8 py-3" >
              {t('no_of_bot_sessions')}
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-100">
          {departments?.length > 0 && departments.map(department  => (
            <tr key={department.id}>
              <td className="px-8 py-3 max-w-0 w-full whitespace-nowrap text-sm text-left font-medium text-gray-900">
                <Link to={`/${companyId}/departments/${department.id}`} className="hover:text-gray-400">
                  {department.name}
                </Link>
              </td>
              <td className="px-8 py-3 text-sm text-center text-gray-500 font-medium">
                {department.botModulesCount}
              </td>
              <td className="px-8 py-3 text-sm text-center text-gray-500 font-medium">
                {department.botUsersCount}
              </td>
              <td className="px-8 py-3 text-sm text-center text-gray-500 font-medium">
                {department.botSessionsCount}
              </td>
            </tr>
          )
          )}
        </tbody>
      </table>
    </div>
  )
}

interface DashboardRouteQueryProps {
  companyId: string;
  userRole: string | null;
}

const DashboardRoute: React.VFC<DashboardRouteQueryProps> = ({ companyId, userRole }) => {
  const { t, i18n } = useTranslation();

  const { data, loading, error } = useQuery(gql`
    query DashboardRouteQuery($companyId: ID!) {
      company(id: $companyId) {
        id
        departments {
          id
          name
          botModulesCount
          botSessionsCount
          botUsersCount
        }
        sessionGraph
        activeSubscriptions {
          id
          name
          expiresAtRepresentation
          credits
          automaticRenewal
          used
        }
        botUsersCount
        botSessionsCount
        botModulesCount
      }
    }
  `, {
    fetchPolicy: 'network-only',
    variables: {
      companyId
    }
  });

  if (userRole != 'admin' && userRole != 'superadmin'){
    return (
      <div>
        <header>
          <div className="flex items-center justify-between h-10">
            <h1 className="text-2xl font-semibold text-gray-900 truncate">
              {t('dashboard')}
            </h1>
          </div>
        </header>
  
        <main className="mt-8 dashboard">
        </main>
      </div>
    )
  }

  return (
    <div>
      <header>
        <div className="flex items-center justify-between h-10">
          <h1 className="text-2xl font-semibold text-gray-900 truncate">
            {t('dashboard')}
          </h1>
        </div>
      </header>

      <main className="mt-8 dashboard">
        {!loading && (
          <>
            <h2 className="text-2xl font-semibold text-gray-900 truncate mb-4">{t('subscriptions')}</h2>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-2 mb-4">
              {data?.company?.activeSubscriptions && (
                <>
                  {data.company!.activeSubscriptions.map((subscription: { id: string, name: string, credits: string; used: string, automaticRenewal: boolean, expiresAtRepresentation:string }) => (
                    <div key={subscription.id} className="item">
                      <StatsCardSubscription key={subscription.id} userRole={userRole} id={subscription.id} companyId={companyId} title={subscription.name} value={subscription.credits} automaticRenewal={subscription.automaticRenewal} subValue={subscription.used} date={subscription.expiresAtRepresentation}>
                        <div className="bg-cyan-500 rounded-full p-2 m-1 self-center">
                          <ChartSquareBarIcon className="w-6 h-6 text-white"/>
                        </div>
                      </StatsCardSubscription>
                    </div>
                  ))}
                </>
              )}
            </div>
            {data?.company?.departments.length == 0 && (
                <>
                  <a className={`mb-4 text-white relative rounded-lg px-6 py-5 shadow-sm flex items-start justify-between space-x-3 bg-orange-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 color-white`} href={'/' + companyId + '/departments'} >
                    <div className="flex flex-col text-white">
                      <h2 className="text-xl font-semibold text-white truncate mb-4">{t('create_first_department')}</h2>
                      <p className="text-xs font-medium text-white">{t('create_first_department_explanation')}</p>
                    </div>
                    <div className="bg-orange-400 rounded-full p-2 self-center">
                      <OfficeBuildingIcon className="w-6 h-6 text-white" viewBox="0 0 20 22"/>
                    </div>
                  </a>
                </>
            )}
            {data?.company?.botUsersCount == 0 && (
                <>

                  <a className={`mb-4 text-white relative rounded-lg px-6 py-5 shadow-sm flex items-start justify-between space-x-3 bg-orange-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 color-white`} href={'/' + companyId + '/bot_users'} >
                    <div className="flex flex-col text-white">
                      <h2 className="text-xl font-semibold text-white truncate mb-4">{t('create_first_bot_user')}</h2>
                      <p className="text-xs font-medium text-white">{t('create_bot_user_explanation')}</p>
                    </div>
                    <div className="bg-orange-400 rounded-full p-2 self-center">
                      <UserGroupIcon className="w-6 h-6 text-white" viewBox="0 0 20 22"/>
                    </div>
                  </a>
                </>
            )}
            {data?.company?.departments.length > 0 && (
            <>
              <h2 className="text-2xl font-semibold text-gray-900 truncate mb-4">{t('information')}</h2>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4">
                <StatsCard title={t('total_bot_users')} value={data?.company!.botUsersCount} href={`${companyId}/bot_users`} >
                  <div className="bg-red-500 rounded-full p-2 self-center">
                    <UserGroupIcon className="w-6 h-6 text-white" viewBox="0 0 20 22"/>
                  </div>
                </StatsCard>
                <StatsCard title={t('total_bot_sessions')} value={data?.company!.botSessionsCount} >
                  <div className="bg-orange-500 rounded-full p-2 m-1 self-center">
                    <ChartSquareBarIcon className="w-6 h-6 text-white"/>
                  </div>
                </StatsCard>
                <StatsCard title={t('total_departments')} value={data?.company!.departments.length} href={`${companyId}/departments`} >
                  <div className="bg-yellow-500 rounded-full p-2 self-center">
                    <OfficeBuildingIcon className="w-6 h-6 text-white"/>
                  </div>
                </StatsCard>
                <StatsCard title={t('total_bot_modules')} value={data?.company!.botModulesCount} href={`${companyId}/bot_modules`}>
                  <div className="bg-cyan-500 rounded-full p-2 self-center">
                    <BookOpenIcon className="w-6 h-6 text-white"/>
                  </div>
                </StatsCard>
              </div>
              <div className="graph mt-4">
                <ChartComponent jsonString={data?.company!.sessionGraph} ></ChartComponent>
              </div>
              <DepartmentsStatsTable companyId={companyId} departments={data?.company!.departments} />
            </>
            )}
          </>
          )}
      </main>
    </div>
  )
}

export default DashboardRoute;
