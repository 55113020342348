import * as yup from "yup";

export const CompanyChannelsSchema = yup.object({
  whatsapp: yup.object({
    accountId: yup.string().required().label("Account ID"),
    apiKey: yup.string().required().label("Api key"),
    mobile: yup.string().required().label("Mobile"),
    providerId: yup.string().required().label("Provider ID"),
  }).required()
})
